import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Category from "../components/category"

const Categories = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const categories = data.allMdx.group
  const pageTitle = "Todas as categorias"
  const byQuantityDesc = (a, b) =>
    a.totalCount > b.totalCount ? -1 : b.totalCount > a.totalCount ? 1 : 0

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Lista com todas a todas as categorias de textos do blog."
      />
      <div className="post-wrapper">
        <h1 itemProp="headline">{pageTitle}</h1>
        {categories
          .sort(byQuantityDesc)
          .map(c => (
            <Category
              key={c.fieldValue}
              catName={c.fieldValue}
              count={c.totalCount}
            />
          ))
          .reduce((prev, curr) => [prev, ", ", curr])}
      </div>
    </Layout>
  )
}

export default Categories

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`
